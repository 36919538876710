<template>
  <div>
    <main-drawer
      :user="userInSidebar"
      :menu="menuDisplay"
    />
    <main-toolbar
      :title="mainTitle"
    >
      <template v-slot:extra>
        <div
          v-if="isInLab"
          v-ripple
          class="toolbar-items"
          style="cursor:pointer"
          @click="$router.push('/mylab')"
        >
          <v-icon color="color-toolbar-btn" size="21" style="padding-bottom:2px;">mdi-test-tube</v-icon>
        </div>

        <v-menu
          v-if="toShowSettingBtn"
          bottom
          left
          content-class="toolbar-menu"
          offset-y
          transition="slide-y-transition"
          min-width="240px"
          max-width="90vh">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-ripple
              slot="activator"
              class="toolbar-items"
              v-bind="attrs"
              v-on="on"
              to=""
            >
              <v-icon color="color-toolbar-btn" size="21" style="padding-bottom:2px;">mdi-cog</v-icon>
            </div>
          </template>
          <v-card>
            <v-list dense class="py-3">
              <v-list-item
                v-if="isAdminContrRev"
                @click="clickContrRevSettings"
              >
                <v-list-item-content
                  v-text="'Client Project Contract Admin Setting'"
                />
              </v-list-item>
              <v-list-item
                v-if="isAdminWbs"
                @click="clickWbsSettings"
              >
                <v-list-item-content
                  v-text="'CI WBS Admin Setting'"
                />
              </v-list-item>
              <v-list-item
                v-if="isAdminSap"
                @click="clickSapSettings"
              >
                <v-list-item-content
                  v-text="'Billing/Payment Term Setting'"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>        
        
        <v-menu
          bottom
          left
          content-class="toolbar-menu"
          offset-y
          transition="slide-y-transition"
          min-width="240px"
          max-width="90vh">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-ripple
              slot="activator"
              class="toolbar-items"
              v-bind="attrs"
              v-on="on"
              to=""
            >
              <v-badge
                :value="!!notifications.length"
                color="error"
                overlap
              >
                <template slot="badge">
                  {{ notifications.length > 9 ? '!' : notifications.length }}
                </template>
                <v-icon color="color-toolbar-btn" size="21" style="padding-bottom:2px;">mdi-bell</v-icon>
              </v-badge>
            </div>
          </template>
          <v-card>
            <v-list dense class="px-1">
              <v-list-item
                v-for="(notification, ni) in (showAllNotifications ? notifications : notifications.slice(0, 10))"
                :key="`notification_${ni}`"
                @click="clickNotification(ni)"
              >
                <v-list-item-content
                  v-text="notification.content || ''"
                />
              </v-list-item>
              <v-list-item
                v-if="!notifications.length"
                class="v-list-item--notification-no-message"
              >
                <v-list-item-content
                  class="grey--text text--lighten-1"
                  v-text="'No message'"
                />
              </v-list-item>
              <v-list-item
                v-else-if="notifications.length > 10"
                class="v-list-item--notification-no-message"
              >
                <v-list-item-content
                  class="grey--text text--lighten-1"
                  v-text="'and others'"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        
        <v-menu
          bottom
          left
          content-class="toolbar-menu"
          offset-y
          transition="slide-y-transition"
          max-width="90vh">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-ripple
              slot="activator"
              class="toolbar-items"
              v-bind="attrs"
              v-on="on"
              to=""
            >
              <v-icon color="color-toolbar-btn" size="24">mdi-account</v-icon>
            </div>
          </template>
          <v-card>
            <v-list dense class="px-1">
              <v-list-item
                @click="logoutMsal"
              >
                <v-list-item-content
                  v-text="'Log out'"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </main-toolbar>

    <v-main class="main-content">
      <v-card
        v-if="false"
        color="red lighten-1"
      >
      <v-snackbar
        top
        color="red lighten-1"
        v-model="alert"
        :timeout="-1"
      >
        <div class="d-flex align-start justify-space-between pb-2 px-1">
          <v-icon size="24" class="mt-2 ml-1 mr-7">mdi-bullhorn-variant</v-icon>
          <div class="text-h6 font-weight-bold">Effective on March 28th, Ginkgo system would route to <u>https://ginkgo.nielseniq.cn.</u></div>
          <v-spacer />
          <v-btn x-small text fab class="ml-n2 mr-n3 mt-0 mb-0 yellow--text"
            @click="alert = false"
          ><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-snackbar>
      </v-card>
      <v-container
        fluid
        grid-list-xl
        pt-1
        px-6
        mb-0
      >
        <v-card v-if="true" class="mt-n4 mb-n2">
          <v-card-text>
            <div class="mt-2 mb-1 d-flex align-start" style="text-align:left">
              <v-icon class="mb-1 ml-1 mr-3 primary--text">mdi-information</v-icon>
              <div class="red--text">
                <div><b>Reminder for template use 模板使用提醒：</b></div>
                <div><b>Services involve technology access, etc. Answer, CX pro 服务使用平台，如Answers，CX pro 提供的：</b></div>
                <div class="ml-4">LA and LSA: Please make sure your contract includes "Access to Services Exhibit."</div>
                <div class="ml-4">LA and LSA: 请确保合同包含《 “获得服务” 附件》/《服务访问权限附件》条款.</div>
              </div>
            </div>
            <div v-if="false" class="mt-2 mb-1 d-flex align-start" style="text-align:left">
              <v-icon class="mb-1 ml-1 mr-3 primary--text">mdi-information</v-icon>
              <div class="red--text">
                <div>The business license of 【 NielsenIQ (Guangzhou) LTD, Beijing Branch】 has been updated, please download the latest business license from system.</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-fade-transition mode="out-in">
          <router-view v-if="userInfoLoaded"></router-view>
          <div v-else class="main-loading">
            <v-progress-circular
              :width="5"
              color="grey"
              indeterminate
              :size="30"
            ></v-progress-circular>
            <div
              class="grey--text"
              :style="{
                'margin-top': '12px',
                'font-size': '14px'
              }"
            >
              Loading
            </div>
          </div>
        </v-fade-transition>
      </v-container>

      <copyright-component />
    </v-main>

    <single-alert-snackbar
      :info="snackbarInfo"
    ></single-alert-snackbar>
  </div>
</template>

<script>
// vuex
import { mapState, mapGetters } from 'vuex'
import { isProductEnv, proRedirUrl, section_color, isInLab } from '@/config'
import { xPost } from '@/functions/http/axios'
import singleAlertSnackbar from '@/components/vuetify-extension/single-alert-snackbar'
import CopyrightComponent from './copyright/copyright.vue'

export default {
  components: {
    singleAlertSnackbar,
    CopyrightComponent,
  },
  data () {
    return {
      // alert
      alert: true,

      /** loading */
      userInfoLoaded: false,
      showAllNotifications: false,

      /** menu & router */
      sourceRoute: '',
      menu: [
        {
          name: 'Legal',
          icon: 'mdi-file-document-edit-outline',
          alias: ['/legal'],
          children: [
            {
              name: 'My Requests',
              alias: [
                '/legal/request/details/client-project',
                '/legal/request/details/other'
              ],
              path: '/legal/request/list'
            },
            {
              name: 'Business Certifications/Licenses',
              path: '/legal/business-cl'
            },
            {
              name: 'Legal Template',
              path: '/legal/template'
            },
            {
              name: 'Legal Training Corner',
              path: '/legal/training-corner'
            }
          ]
        },
        {
          name: 'CI WBS',
          icon: 'mdi-file-document-edit-outline',
          alias: ['/ci/wbs'],
          children: [
            {
              name: 'CI WBS Exceptional Request',
              alias: ['/ci/wbs/details'],
              path: '/ci/wbs/list'
            },
            {
              name: 'CI WBS Code Pool',
              path: '/ci/wbs/code-pool'
            }
          ]
        },
        {
          name: 'Approval',
          icon: 'mdi-stamper',
          active_class: section_color['approval'] || null,
          path: '/approval'
        },
      ],

      // admin access
      adminAccess: {
        contrRev: false,
        wbs: false,
        vendorCCSpec: false,
        sap: [],
      },

      snackbarInfo: {},

      notifications: [],

      isProductEnv: isProductEnv,
    }
  },
  computed: {
    /**
     * vuex - state / getters
     */
    ...mapState('user', {
      msal: state => state.msal,
    }),
    ...mapState('app', {
      mainTitle: state => state.mainTitle,
    }),
    ...mapGetters('user', [
      'loginStatus',
      'userEmail',
      'userInfo'
    ]),

    // for MyLab testing
    isInLab () {
      return isInLab(this.userEmail)
    },

    isAdminContrRev() {
      return !!this.adminAccess.contrRev
    },
    isAdminWbs() {
      return !!this.adminAccess.wbs
    },
    isAdminVendorCcSpec() {
      return !!this.adminAccess.vendorCCSpec
    },
    isAdminSap() {
      return !!this.adminAccess.sap.length
    },
    toShowSettingBtn() {
      return (
        this.isAdminContrRev ||
        this.isAdminWbs ||
        this.isAdminSap)
    },

    userInSidebar () {
      return {
        name: this.userInfo.name.fullName || '',
        icon: this.userInfo.photoUrl || ''
      }
    },

    /**
     * get route path in menu
     */
    menuDisplay () {
      return [
        ...this.menu,
        ...this.isAdminVendorCcSpec
        ? [{
          name: 'Admin',
          icon: 'mdi-tools',
          alias: ['/admin'],
          children: [
            ...this.isAdminVendorCcSpec
            ? [{
              name: 'Vendor Contract - Cost Center',
              path: '/admin/vendor-cc'
            }] : [],
          ],
        }] : []
      ]
    },
    // route () {
    //   let ret = ''
    //   this.menu.forEach(el => {
    //     if (el.children) {
    //       el.children.forEach(el2 => {
    //         if (this.$route.path.indexOf(el2.path) === 0)
    //           ret = el2.path
    //       })
    //     } else if (this.$route.path.indexOf(el.path) === 0) {
    //       ret = el.path
    //     }
    //   })
    //   return ret
    // },
  },
  methods: {
    async init () {
      if (!(await this.checkEnv())) { return }
      await this.refreshUserWholly(3600)
      this.userInfoLoaded = true
    },

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    /**
     * go back to login page
     */
    logoutMsal () {
      this.$eventBus.$emit('msal-logout')
    },

    /**
     * login
     * 1. get info - google
     * 2. set info - store / cookie
     */
    async checkEnv () {
      try {
        if (!isProductEnv && window.location.origin !== proRedirUrl) {
          const req = xPost(
            'tester',
            'check',
            {
              user: this.userEmail
            }
          )
          const res = await req
          if (!(res.data && res.data.status === 200)) {
            this.$router.push('/to-live')
            return false
          }
        }
      } catch (err) {
        console.log('Error [check tester for test link]')
        console.log(err)
      }

      return true
    },

    // /**
    //  * merge soure route and target route then push
    //  */
    // mergedRouterPush(route) {
    //   this.$router.push(this.sourceRoute + route)
    // },
    // /**
    //  * close all bottom menu then run 'mergedRouterPush'
    //  */
    // closeAndMergedRouterPush(menuIndex, route) {
    //   this.menu[menuIndex].pop = false
    //   this.mergedRouterPush(route)
    // },

    setSnackbarInfo (info) {
      this.snackbarInfo = info
    },

    /**
     * request notification (refresh per minute)
     */
    async requestAdminAccess() {
      try {
        this.isWbsCodeEditorLoading = true
        const res = await xPost(
            'admin',
            'access',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            this.adminAccess.contrRev = res.data.contr_rev || false
            this.adminAccess.wbs = res.data.wbs || false
            this.adminAccess.vendorCCSpec = res.data.vendor_cc_spec || false
            
            this.adminAccess.sap = []
            for (const key in  res.data.sap) {
              if (!!res.data.sap[key]) { this.adminAccess.sap.push(key) }
            }
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                // this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get wbs code editor approvers]')
        // console.log(err)
      }
      this.isWbsCodeEditorLoading = false
    },
    async requestNofitication (repSec) {
      if (!!repSec) {
        setTimeout(() => {
          this.requestNofitication(repSec)
        }, repSec * 1000);
      }
      try {
        const req = xPost(
          'notification',
          'getMyNotifications',
          {
            loginStatus: this.loginStatus
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.notifications = res.data.data.map(el => ({
            type: el.Type,
            token: el.Token,
            content: el.Content,
            time: el.Time
          }))
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              // this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get notifications]')
        // console.log(err)
      }
    },
    async clearNotificationByType (token, type) {
      try {
        const req = xPost(
          'notification',
          'clearNotificationByType',
          {
            loginStatus: this.loginStatus,
            token,
            type
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.notifications = res.data.data.map(el => ({
            type: el.Type,
            token: el.Token,
            content: el.Content,
            time: el.Time
          }))
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              // this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get notifications]')
        // console.log(err)
      }
    },

    clickNotification (index) {
      if (index < 0) return
      let to = ''
      switch (this.notifications[index].type) {
        case 'Client Project Contract Detail':
          if (!!this.notifications[index].token) {
            to = `/legal/request/details/client-project/${this.notifications[index].token}`
            this.clearNotificationByType(this.notifications[index].token, 'Client Project Contract Detail')
          }
          break
        case 'Other Legal Requests Detail':
          if (!!this.notifications[index].token) {
            to = `/legal/request/details/other/${this.notifications[index].token}`
            this.clearNotificationByType(this.notifications[index].token, 'Other Legal Requests Detail')
          }
          break
        case 'Vendor Contract Detail':
          if (!!this.notifications[index].token) {
            to = `/legal/request/details/vendor/${this.notifications[index].token}`
            this.clearNotificationByType(this.notifications[index].token, 'Vendor Contract Detail')
          }
          break
        case 'WBS Request Detail':
          if (!!this.notifications[index].token) {
            to = `/ci/wbs/details/${this.notifications[index].token}`
            this.clearNotificationByType(this.notifications[index].token, 'WBS Request Detail')
          }
          break
        default:
          break
      }
      if (!!to) this.$router.push(to)
    },
    clearNotification (notification) {
      switch (notification.type) {
        case 'Client Project Contract Detail':
        case 'Other Legal Requests Detail':
        case 'WBS Request Detail':
          if (notification.token) this.clearNotificationByType(notification.token, notification.type)
          break
          
        default:
          break
      }
    },
    async refreshUserWholly (secRepNoti) {
      // seconds to Repeat getting Notifications
      secRepNoti = secRepNoti || 0
      const requestAdminAccess = this.requestAdminAccess()
      const requestNofitication = this.requestNofitication(secRepNoti)
      await requestAdminAccess
      await requestNofitication
    },

    /**
      Settings
     */
    clickContrRevSettings () {
      this.$router.push('/admin/contract-review')
    },
    clickWbsSettings () {
      this.$router.push('/admin/wbs')
    },
    clickSapSettings () {
      this.$router.push('/admin/billing-payment-term')
    },
  },
  async created () {
    await this.init()
    this.$eventBus.$on('snackbar', this.setSnackbarInfo)
    this.$eventBus.$on('clearNotification', this.clearNotification)
    this.$eventBus.$on('refreshNotification', this.requestNofitication)
    this.$eventBus.$on('refreshUserWholly', this.refreshUserWholly)
    // // this.$eventBus.$on('snackbar', val => this.snackbarChange(val))
  },
  async mounted () {
    // var src = 'node_modules/eruda/eruda.min.js';
    // if (!/eruda=true/.test(window.location) && localStorage.getItem('active-eruda') != 'true') return;
    // document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
    // document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
  },
  beforeDestroy () {
    this.$eventBus.$off('snackbar', this.setSnackbarInfo)
    this.$eventBus.$off('clearNotification', this.clearNotification)
    this.$eventBus.$off('refreshNotification', this.requestNofitication)
    this.$eventBus.$off('refreshUserWholly', this.refreshUserWholly)
  }
}
</script>

<style lang="sass">
.main-content
  min-height: calc(100vh - 85px)
  padding-bottom: 100px !important
  background: #eee
  text-align: center


.main-loading
  width: 100%
  text-align: center
  padding-top: calc(50vh - 85px)


.v-list-item--notification-no-message
  .v-list-item
    .v-list-item__title
      color: lightgrey !important
    

    &:hover
      .v-list-item__title
        color: lightgrey !important
      

      background-color: rgba(0,0,0,0)!important
      -webkit-box-shadow: none !important
      box-shadow: none !important
    
  


.copyright
  position: absolute
  bottom: -100px
  text-align: center !important
  width: 100%
  color: rgba(0,0,0,0.78)
  font-size: 12px

</style>
